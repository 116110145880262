import React from "react";
import { useTranslation } from "@ca-dmv-radv/translation";
import { RadioGroup, RadioItem, Icon } from "@ca-dmv/core";
import { getOptedCategory, postOptedCategory } from "@ca-dmv-radv/data";

export default function OptionalDocumentPrompt({ applicationType, proofOfDocumentCategory, setWillUpload, optedIn }) {
  const { t } = useTranslation();

  const message = optedIn === true
    ? t(
        `${applicationType}-screens-category${proofOfDocumentCategory.categoryId}-optionalYesMessage`,
        `Please provide a photo of your ${proofOfDocumentCategory.categoryName} by selecting one of the options below. The signature will be used on the new card once it has been uploaded and approved.`
      )
    : t(
        `${applicationType}-screens-category${proofOfDocumentCategory.categoryId}-optionalNoMessage`,
        `You have chosen to not upload a ${proofOfDocumentCategory.categoryName} document.`
      );

  return (
    <>
      <RadioGroup
        legend={t(
          `${applicationType}-category${proofOfDocumentCategory.categoryId}-optional-prompt`, 
          `Would you like to upload ${proofOfDocumentCategory.categoryName} documents?`
        )}
        containerClass="mb-8"
        legendClass="text--sm text--h5"
      >
        <RadioItem
            containerClass="mb-8"
            label={t(
              `${applicationType}-category${proofOfDocumentCategory.categoryId}-optionalUpload-yes`, 
              "Yes"
            )}
            onChange={async () => {
              await postOptedCategory(applicationType, proofOfDocumentCategory.categoryId, true);
              setWillUpload(true);
            }}
            required={false}
            checked={optedIn === true}
          />
          <RadioItem
            containerClass="mb-8"
            label={t(
              `${applicationType}-category${proofOfDocumentCategory.categoryId}-optionalUpload-no`, 
              "No"
            )}
            onChange={async () => {
              await postOptedCategory(applicationType, proofOfDocumentCategory.categoryId, false);
              setWillUpload(false);
            }}
            required={false}
            checked={optedIn === false}
          />
      </RadioGroup>
      {optedIn !== null && (
        <div className="show--flex">
          <Icon icon="check" className="mr-8 mt-5 min-width--15" />
          <p className="text--sm text--blue-dark-2 mb-16">{message}</p>
        </div>
      )}
    </>
  );
}